var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showCloseButton
      ? _c(
          "button",
          {
            staticClass: "js-join-convo__close",
            attrs: { "data-cy": "panel-join-close" },
            on: { click: _vm.closeMemberPanel },
          },
          [
            _c("img", {
              attrs: {
                src: "/img/ui/icon-close-black.svg",
                alt: "close",
                width: "25",
                height: "25",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.cookieSettings == null || _vm.panelCookie === true
      ? _c(
          "section",
          {
            staticClass: "u-m-t8 u-m-t5@m u-m-t2@s c-cookies",
            attrs: { id: "cookies-panel" },
          },
          [
            _c("div", [
              _vm._m(0),
              _vm._v(" "),
              _c("table", [
                _c("tr", [
                  _c("td", [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cookiesNecessary,
                            expression: "cookiesNecessary",
                          },
                        ],
                        attrs: { type: "checkbox", value: "1", disabled: "" },
                        domProps: {
                          checked: Array.isArray(_vm.cookiesNecessary)
                            ? _vm._i(_vm.cookiesNecessary, "1") > -1
                            : _vm.cookiesNecessary,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cookiesNecessary,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "1",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.cookiesNecessary = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.cookiesNecessary = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.cookiesNecessary = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Necessary cookies")]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("We use necessary cookies to make our site work."),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cookiesAnalytics,
                            expression: "cookiesAnalytics",
                          },
                        ],
                        attrs: { type: "checkbox", value: "1" },
                        domProps: {
                          checked: Array.isArray(_vm.cookiesAnalytics)
                            ? _vm._i(_vm.cookiesAnalytics, "1") > -1
                            : _vm.cookiesAnalytics,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cookiesAnalytics,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "1",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.cookiesAnalytics = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.cookiesAnalytics = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.cookiesAnalytics = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Analytics tracking")]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "We also use analytics tracking to measure your use of the site, to help us make improvements."
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cookiesMarketing,
                            expression: "cookiesMarketing",
                          },
                        ],
                        attrs: { type: "checkbox", value: "1" },
                        domProps: {
                          checked: Array.isArray(_vm.cookiesMarketing)
                            ? _vm._i(_vm.cookiesMarketing, "1") > -1
                            : _vm.cookiesMarketing,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cookiesMarketing,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "1",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.cookiesMarketing = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.cookiesMarketing = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.cookiesMarketing = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Marketing cookies")]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "We also use marketing cookies to to help us improve the relevancy of advertising campaigns you receive."
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "u-m-b3" }, [
                _c(
                  "a",
                  {
                    staticClass: "c-btn--profile",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cookiesRejectAll.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Reject All")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "c-btn--profile",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cookiesAcceptAll.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Accept All")]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "st u-m-b3" }, [
                _vm._v(
                  "By pressing the Save and Close button, you consent to the use of the enabled categories of cookies. You can change your preferences and find further information in our Cookie Policy."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "c-btn u-width-full u-m-b2",
                    attrs: { href: "#", "data-cy": "cookies-save-close" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cookiesSave.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Save & Close")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ]
        )
      : _vm.panelJoin
      ? _c("section", { staticClass: "u-m-t8 u-m-t5@m u-m-t2@s" }, [
          _c("div", [
            _vm._m(2),
            _vm._v(" "),
            _vm.lastLogin == null
              ? _c(
                  "a",
                  {
                    staticClass: "c-btn u-width-full u-m-b2",
                    attrs: { href: "#", "data-cy": "sign-up-new" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showPanel("signup")
                      },
                    },
                  },
                  [_vm._v("Sign up")]
                )
              : _c(
                  "a",
                  {
                    staticClass: "c-btn c-btn--blue u-width-full u-m-b2",
                    attrs: { href: "#", "data-cy": "login-again" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showPanel("login")
                      },
                    },
                  },
                  [_vm._v("Login")]
                ),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm.lastLogin == null
              ? _c(
                  "a",
                  {
                    staticClass: "c-btn c-btn--blue u-width-full",
                    attrs: { href: "#", "data-cy": "login-new" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showPanel("login")
                      },
                    },
                  },
                  [_vm._v("Login")]
                )
              : _c(
                  "a",
                  {
                    staticClass: "c-btn u-width-full",
                    attrs: { href: "#", "data-cy": "sign-up-again" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showPanel("signup")
                      },
                    },
                  },
                  [_vm._v("Sign up")]
                ),
          ]),
          _vm._v(" "),
          _vm._m(4),
        ])
      : _vm.panelSignup
      ? _c("section", { staticClass: "u-m-t8 u-m-t5@m u-m-t2@s" }, [
          _c("div", { staticClass: "u-align-center" }, [
            _c("img", {
              staticClass: "u-m-b3 u-m-b1@s",
              attrs: {
                src: "/img/logo-dyadey-main.svg",
                alt: "Dyadey",
                width: "171",
                height: "33",
              },
            }),
            _vm._v(" "),
            _c("h4", { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" }, [
              _vm._v("Join the Conversation"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "u-m-b2 u-text-small@s u-m-b2@s" }, [
              _vm._v(
                "Create an account to become a member of the communities you are passionate about."
              ),
              _c("br"),
              _c(
                "a",
                {
                  staticClass: "u-underline-text",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showPanel("login")
                    },
                  },
                },
                [_vm._v("Already registered?")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.registration &&
          typeof _vm.registration.success != "undefined" &&
          _vm.registration.success === false
            ? _c("div", { staticClass: "u-c-red" }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "u-align-center u-m-b2 u-text-small@s u-m-b2@s",
                    attrs: { "data-cy": "error-registering" },
                  },
                  [_vm._v("Error Registering")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "u-m-b4 u-text-meta u-m-b2@s" }, [
                  _vm._v(
                    "There were errors with your registration - please check the messages in red below"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "c-btn c-btn--blue2 u-width-full u-m-b2",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.facebookLogin.apply(null, arguments)
                },
              },
            },
            [_vm._v("Sign in with Facebook")]
          ),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "registerForm",
              attrs: { method: "post", action: "/register" },
              on: { submit: _vm.processRecaptcha },
            },
            [
              _c("div", { staticClass: "u-flex" }, [
                _c("div", { staticClass: "u-width-half u-m-r1 u-m-b1" }, [
                  _c(
                    "label",
                    { staticClass: "c-label", attrs: { for: "first_name" } },
                    [_vm._v("First Name")]
                  ),
                  _vm._v(" "),
                  _vm.registration &&
                  typeof _vm.registration.errors != "undefined" &&
                  (typeof _vm.registration.errors.first_name != "undefined" ||
                    typeof _vm.registration.errors.surname != "undefined")
                    ? _c("div", {
                        staticClass: "error u-text-meta u-c-red",
                        domProps: {
                          innerHTML: _vm._s(
                            typeof _vm.registration.errors.first_name !=
                              "undefined"
                              ? _vm.registration.errors.first_name[
                                  Object.keys(
                                    _vm.registration.errors.first_name
                                  )[0]
                                ]
                              : "&nbsp;"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "c-textbox u-width-full",
                    attrs: {
                      type: "text",
                      name: "first_name",
                      id: "first_name",
                      "data-cy": "firstname",
                    },
                    domProps: {
                      value:
                        _vm.registration &&
                        typeof _vm.registration.fields != "undefined" &&
                        typeof _vm.registration.fields.first_name != "undefined"
                          ? _vm.registration.fields.first_name
                          : "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "u-width-half u-m-l1 u-m-b1" }, [
                  _c(
                    "label",
                    { staticClass: "c-label", attrs: { for: "surname" } },
                    [_vm._v("Surname")]
                  ),
                  _vm._v(" "),
                  _vm.registration &&
                  typeof _vm.registration.errors != "undefined" &&
                  (typeof _vm.registration.errors.first_name != "undefined" ||
                    typeof _vm.registration.errors.surname != "undefined")
                    ? _c("div", {
                        staticClass: "error u-text-meta u-c-red",
                        domProps: {
                          innerHTML: _vm._s(
                            typeof _vm.registration.errors.surname !=
                              "undefined"
                              ? _vm.registration.errors.surname[
                                  Object.keys(
                                    _vm.registration.errors.surname
                                  )[0]
                                ]
                              : "&nbsp;"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "c-textbox u-width-full",
                    attrs: {
                      type: "text",
                      name: "surname",
                      id: "surname",
                      "data-cy": "surname",
                    },
                    domProps: {
                      value:
                        _vm.registration &&
                        typeof _vm.registration.fields != "undefined" &&
                        typeof _vm.registration.fields.surname != "undefined"
                          ? _vm.registration.fields.surname
                          : "",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "u-m-b1" }, [
                _c(
                  "label",
                  { staticClass: "c-label", attrs: { for: "email_reg" } },
                  [_vm._v("Email")]
                ),
                _vm._v(" "),
                _vm.registration &&
                typeof _vm.registration.errors != "undefined" &&
                typeof _vm.registration.errors.email != "undefined"
                  ? _c("div", {
                      staticClass: "error u-text-meta u-c-red",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.registration.errors.email[
                            Object.keys(_vm.registration.errors.email)[0]
                          ]
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  staticClass: "c-textbox u-width-full",
                  attrs: {
                    type: "text",
                    name: "email",
                    id: "email_reg",
                    "data-cy": "email",
                  },
                  domProps: {
                    value:
                      _vm.registration &&
                      typeof _vm.registration.fields != "undefined" &&
                      typeof _vm.registration.fields.email != "undefined"
                        ? _vm.registration.fields.email
                        : "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "u-m-b1" }, [
                _c(
                  "label",
                  { staticClass: "c-label", attrs: { for: "username" } },
                  [_vm._v("Username")]
                ),
                _vm._v(" "),
                _vm.registration &&
                typeof _vm.registration.errors != "undefined" &&
                typeof _vm.registration.errors.username != "undefined"
                  ? _c("div", {
                      staticClass: "error u-text-meta u-c-red",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.registration.errors.username[
                            Object.keys(_vm.registration.errors.username)[0]
                          ]
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  staticClass: "c-textbox u-width-full",
                  attrs: {
                    type: "text",
                    name: "username",
                    id: "username",
                    "data-cy": "username",
                  },
                  domProps: {
                    value:
                      _vm.registration &&
                      typeof _vm.registration.fields != "undefined" &&
                      typeof _vm.registration.fields.username != "undefined"
                        ? _vm.registration.fields.username
                        : "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "u-m-b2" }, [
                _c(
                  "label",
                  { staticClass: "c-label", attrs: { for: "password_reg" } },
                  [_vm._v("Password")]
                ),
                _vm._v(" "),
                _vm.registration &&
                typeof _vm.registration.errors != "undefined" &&
                typeof _vm.registration.errors.password != "undefined"
                  ? _c("div", {
                      staticClass: "error u-text-meta u-c-red",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.registration.errors.password[
                            Object.keys(_vm.registration.errors.password)[0]
                          ]
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  staticClass: "c-textbox u-width-full",
                  attrs: {
                    type: "password",
                    name: "password",
                    id: "password_reg",
                    "data-cy": "password",
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("vue-recaptcha", {
                ref: "invisRecaptcha",
                attrs: {
                  size: "invisible",
                  badge: "bottomright",
                  loadRecaptchaScript: true,
                  sitekey: _vm.recaptchaSiteKey,
                },
                on: {
                  verify: _vm.recaptchaVerifyRegistration,
                  expired: _vm.recaptchaExpired,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(7),
        ])
      : _vm.panelLogin
      ? _c(
          "section",
          { staticClass: "u-m-t8 u-m-t5@m u-m-t2@s" },
          [
            _vm.invalid === false
              ? [
                  _c("div", { staticClass: "u-align-center" }, [
                    _c("img", {
                      staticClass: "u-m-b3 u-m-b1@s",
                      attrs: {
                        src: "/img/logo-dyadey-main.svg",
                        alt: "Dyadey",
                        width: "171",
                        height: "33",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "h4",
                      { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" },
                      [_vm._v("Welcome Back!")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "u-m-b4 u-text-small@s u-m-b2@s" }, [
                      _vm._v(
                        "Sign in with your previously registered account below.  "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "u-underline-text",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.showPanel("signup")
                            },
                          },
                        },
                        [_vm._v("Not yet registered?")]
                      ),
                      _vm._v("."),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.lastLogin == null || _vm.lastLogin === "facebook"
                    ? [
                        _c(
                          "a",
                          {
                            staticClass:
                              "c-btn c-btn--blue2 u-width-full u-m-b2",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.facebookLogin.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Sign in with Facebook")]
                        ),
                      ]
                    : [
                        _vm.registration &&
                        typeof _vm.registration.success != "undefined" &&
                        _vm.registration.success === true
                          ? _c("div", { staticClass: "u-c-red" }, [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "u-align-center u-m-b2 u-text-small@s",
                                },
                                [_vm._v("Successfully Registered")]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "u-m-b2 u-text-meta" }, [
                                _vm._v(
                                  "You have successfully registered - you can now sign in below"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            ref: "loginForm",
                            attrs: { method: "post", action: "/" },
                            on: { submit: _vm.processRecaptcha },
                          },
                          [
                            _vm._m(8),
                            _vm._v(" "),
                            _vm._m(9),
                            _vm._v(" "),
                            _vm._m(10),
                            _vm._v(" "),
                            _c("vue-recaptcha", {
                              ref: "invisRecaptcha",
                              attrs: {
                                size: "invisible",
                                badge: "bottomright",
                                loadRecaptchaScript: true,
                                sitekey: _vm.recaptchaSiteKey,
                              },
                              on: {
                                verify: _vm.recaptchaVerifyLogin,
                                expired: _vm.recaptchaExpired,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "u-m-t1 u-m-b2" }, [
                          _c(
                            "a",
                            {
                              staticClass: "u-underline-text",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showPanel("forgotten")
                                },
                              },
                            },
                            [_vm._v("Forgot your login details?")]
                          ),
                        ]),
                      ],
                  _vm._v(" "),
                  _vm._m(11),
                  _vm._v(" "),
                  _vm.lastLogin == null || _vm.lastLogin === "facebook"
                    ? [
                        _vm.registration &&
                        typeof _vm.registration.success != "undefined" &&
                        _vm.registration.success === true
                          ? _c("div", { staticClass: "u-c-red" }, [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "u-align-center u-m-b2 u-text-small@s",
                                },
                                [_vm._v("Successfully Registered")]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "u-m-b2 u-text-meta" }, [
                                _vm._v(
                                  "You have successfully registered - you can now sign in below"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            ref: "loginForm",
                            attrs: { method: "post", action: "/" },
                            on: { submit: _vm.processRecaptcha },
                          },
                          [
                            _vm._m(12),
                            _vm._v(" "),
                            _vm._m(13),
                            _vm._v(" "),
                            _vm._m(14),
                            _vm._v(" "),
                            _c("vue-recaptcha", {
                              ref: "invisRecaptcha",
                              attrs: {
                                size: "invisible",
                                badge: "bottomright",
                                loadRecaptchaScript: true,
                                sitekey: _vm.recaptchaSiteKey,
                              },
                              on: {
                                verify: _vm.recaptchaVerifyLogin,
                                expired: _vm.recaptchaExpired,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "u-m-t1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "u-underline-text",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showPanel("forgotten")
                                },
                              },
                            },
                            [_vm._v("Forgot your login details?")]
                          ),
                        ]),
                      ]
                    : [
                        _c(
                          "a",
                          {
                            staticClass:
                              "c-btn c-btn--blue2 u-width-full u-m-b2",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.facebookLogin.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Sign in with Facebook")]
                        ),
                      ],
                  _vm._v(" "),
                  _vm._m(15),
                ]
              : [
                  _c("div", { staticClass: "u-align-center" }, [
                    _c("img", {
                      staticClass: "u-m-b3 u-m-b1@s",
                      attrs: {
                        src: "/img/logo-dyadey-main.svg",
                        alt: "Dyadey",
                        width: "171",
                        height: "33",
                      },
                    }),
                    _vm._v(" "),
                    _vm.invalid === true
                      ? _c(
                          "h4",
                          { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" },
                          [_vm._v("Login Failed")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "u-m-b2 u-text-small@s u-m-b2@s u-c-red" },
                      [
                        _vm._v(
                          "Invalid account credentials - please try to login again."
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.registration &&
                  typeof _vm.registration.success != "undefined" &&
                  _vm.registration.success === true
                    ? _c("div", { staticClass: "u-c-red" }, [
                        _c(
                          "h5",
                          {
                            staticClass: "u-align-center u-m-b2 u-text-small@s",
                          },
                          [_vm._v("Successfully Registered")]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "u-m-b2 u-text-meta" }, [
                          _vm._v(
                            "You have successfully registered - you can now sign in below"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      ref: "loginForm",
                      attrs: { method: "post", action: "/" },
                      on: { submit: _vm.processRecaptcha },
                    },
                    [
                      _vm._m(16),
                      _vm._v(" "),
                      _vm._m(17),
                      _vm._v(" "),
                      _vm._m(18),
                      _vm._v(" "),
                      _c("vue-recaptcha", {
                        ref: "invisRecaptcha",
                        attrs: {
                          size: "invisible",
                          badge: "bottomright",
                          loadRecaptchaScript: true,
                          sitekey: _vm.recaptchaSiteKey,
                        },
                        on: {
                          verify: _vm.recaptchaVerifyLogin,
                          expired: _vm.recaptchaExpired,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "u-m-t1" }, [
                    _c(
                      "a",
                      {
                        staticClass: "u-underline-text",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showPanel("forgotten")
                          },
                        },
                      },
                      [_vm._v("Forgot your login details?")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(19),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "c-btn c-btn--blue2 u-width-full u-m-b2",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.facebookLogin.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Sign in with Facebook")]
                  ),
                  _vm._v(" "),
                  _vm._m(20),
                ],
          ],
          2
        )
      : _vm.panelForgotten
      ? _c("section", { staticClass: "u-m-t8 u-m-t5@m u-m-t2@s" }, [
          _vm._m(21),
          _vm._v(" "),
          _vm._m(22),
        ])
      : _vm.panelReset
      ? _c("section", { staticClass: "u-m-t8 u-m-t5@m u-m-t2@s" }, [
          _vm._m(23),
          _vm._v(" "),
          _c(
            "form",
            { attrs: { method: "post", action: _vm.$route.fullPath } },
            [
              _c("div", { staticClass: "u-m-b1" }, [
                _c(
                  "label",
                  { staticClass: "c-label", attrs: { for: "password_reset" } },
                  [_vm._v("Password")]
                ),
                _vm._v(" "),
                _vm.reset &&
                typeof _vm.reset.errors != "undefined" &&
                typeof _vm.reset.errors.password_reset != "undefined"
                  ? _c("div", {
                      staticClass: "error u-text-meta u-c-red",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.reset.errors.password_reset[
                            Object.keys(_vm.reset.errors.password_reset)[0]
                          ]
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  staticClass: "c-textbox u-width-full",
                  attrs: {
                    type: "password",
                    name: "password_reset",
                    id: "password_reset",
                  },
                }),
              ]),
              _vm._v(" "),
              _vm._m(24),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center" }, [
      _c("img", {
        staticClass: "u-m-b3 u-m-b1@s",
        attrs: {
          src: "/img/logo-dyadey-main.svg",
          alt: "Dyadey",
          width: "171",
          height: "33",
        },
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" }, [
        _vm._v("Consent to cookies and tracking"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "st u-m-b4 u-text-small@s u-m-b2@s" }, [
        _vm._v(
          "Please read about how cookies are used and how they can be managed on our website."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center u-m-t2 u-p-b5" }, [
      _c("a", { attrs: { href: "/cookies" } }, [
        _c("img", {
          attrs: {
            src: "/img/icons/icon-more-information.svg",
            width: "20",
            height: "20",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "u-text-meta u-color-grey2 u-p-t1" }, [
          _c("a", { staticClass: "u-bold", attrs: { href: "/cookies" } }, [
            _vm._v("Cookie policy"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center" }, [
      _c("img", {
        staticClass: "u-m-b3 u-m-b1@s",
        attrs: {
          src: "/img/logo-dyadey-main.svg",
          alt: "Dyadey",
          width: "171",
          height: "33",
        },
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" }, [
        _vm._v("Join the Conversation"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "u-m-b4 u-text-small@s u-m-b2@s" }, [
        _vm._v(
          "Signing in to Dyadey will allow you to immerse yourself in the content you love and have a voice in the communities you are passionate about."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-join-conversation__split" }, [
      _c("p", [_vm._v("OR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center u-m-t2 u-p-b5" }, [
      _c("a", { attrs: { href: "/contact" } }, [
        _c("img", {
          attrs: {
            src: "/img/icons/icon-more-information.svg",
            width: "20",
            height: "20",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "u-text-meta u-color-grey2 u-p-t1" }, [
          _vm._v("Support"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-join-conversation__split" }, [
      _c("p", [_vm._v("OR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("input", {
        staticClass: "c-btn u-width-full",
        attrs: { type: "submit", value: "Sign Up", "data-cy": "submit" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", { staticClass: "u-p-t2 u-p-t4@m u-p-t2@s" }, [
      _vm._v(
        "By signing in with Facebook or entering your email you agree to our "
      ),
      _c("a", { staticClass: "u-bold", attrs: { href: "/terms" } }, [
        _vm._v("Terms of use"),
      ]),
      _vm._v(",\n            "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/privacy" } }, [
        _vm._v("Privacy policy"),
      ]),
      _vm._v(" & "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/cookies" } }, [
        _vm._v("Cookie policy"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b1" }, [
      _c("label", { staticClass: "c-label", attrs: { for: "email_login" } }, [
        _vm._v("Email or Username"),
      ]),
      _vm._v(" "),
      _c("input", { attrs: { type: "hidden", name: "loggingin", value: "1" } }),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "text",
          name: "email",
          id: "email_login",
          "data-cy": "login-email",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b2" }, [
      _c(
        "label",
        { staticClass: "c-label", attrs: { for: "password_login" } },
        [_vm._v("Password")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "password",
          name: "password",
          id: "password_login",
          "data-cy": "login-password",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("input", {
        staticClass: "c-btn u-width-full",
        attrs: { type: "submit", value: "Login", "data-cy": "login-submit" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-join-conversation__split" }, [
      _c("p", [_vm._v("OR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b1" }, [
      _c("label", { staticClass: "c-label", attrs: { for: "email_login" } }, [
        _vm._v("Email or Username"),
      ]),
      _vm._v(" "),
      _c("input", { attrs: { type: "hidden", name: "loggingin", value: "1" } }),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "text",
          name: "email",
          id: "email_login",
          "data-cy": "login-email-new",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b2" }, [
      _c(
        "label",
        { staticClass: "c-label", attrs: { for: "password_login" } },
        [_vm._v("Password")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "password",
          name: "password",
          id: "password_login",
          "data-cy": "login-password-new",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("input", {
        staticClass: "c-btn u-width-full",
        attrs: {
          type: "submit",
          value: "Login",
          "data-cy": "login-submit-new",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", { staticClass: "u-p-t2 u-p-t4@m u-p-t2@s" }, [
      _vm._v(
        "By signing in with Facebook or entering your email you agree to our "
      ),
      _c("a", { staticClass: "u-bold", attrs: { href: "/terms" } }, [
        _vm._v("Terms of use"),
      ]),
      _vm._v(",\n                "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/privacy" } }, [
        _vm._v("Privacy policy"),
      ]),
      _vm._v(" & "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/cookies" } }, [
        _vm._v("Cookie policy"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b1" }, [
      _c("label", { staticClass: "c-label", attrs: { for: "email_login" } }, [
        _vm._v("Email or Username"),
      ]),
      _vm._v(" "),
      _c("input", { attrs: { type: "hidden", name: "loggingin", value: "1" } }),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "text",
          name: "email",
          id: "email_login",
          "data-cy": "login-email-invalid",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-m-b2" }, [
      _c(
        "label",
        { staticClass: "c-label", attrs: { for: "password_login" } },
        [_vm._v("Password")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "c-textbox u-width-full",
        attrs: {
          type: "password",
          name: "password",
          id: "password_login",
          "data-cy": "login-password-invalid",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("input", {
        staticClass: "c-btn u-width-full",
        attrs: {
          type: "submit",
          value: "Login",
          "data-cy": "login-submit-invalid",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-join-conversation__split u-m-t2" }, [
      _c("p", [_vm._v("OR")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", { staticClass: "u-p-t2 u-p-t4@m u-p-t2@s" }, [
      _vm._v(
        "By signing in with Facebook or entering your email you agree to our "
      ),
      _c("a", { staticClass: "u-bold", attrs: { href: "/terms" } }, [
        _vm._v("Terms of use"),
      ]),
      _vm._v(",\n                "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/privacy" } }, [
        _vm._v("Privacy policy"),
      ]),
      _vm._v(" & "),
      _c("a", { staticClass: "u-bold", attrs: { href: "/cookies" } }, [
        _vm._v("Cookie policy"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center" }, [
      _c("img", {
        staticClass: "u-m-b3 u-m-b1@s",
        attrs: {
          src: "/img/logo-dyadey-main.svg",
          alt: "Dyadey",
          width: "171",
          height: "33",
        },
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" }, [
        _vm._v("Password Reset Request"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "u-m-b4 u-text-small@s u-m-b2@s" }, [
        _vm._v(
          "Please enter your email address below and we will email you a link to reset your password."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("form", { attrs: { method: "post", action: "/f" } }, [
      _c("div", { staticClass: "u-m-b1" }, [
        _c(
          "label",
          { staticClass: "c-label", attrs: { for: "email_forgotten" } },
          [_vm._v("Email")]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "c-textbox u-width-full",
          attrs: { type: "text", name: "email", id: "email_forgotten" },
        }),
      ]),
      _vm._v(" "),
      _c("div", {}, [
        _c("input", {
          staticClass: "c-btn u-width-full",
          attrs: { type: "submit", value: "Remind Me" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "u-align-center" }, [
      _c("img", {
        staticClass: "u-m-b3 u-m-b1@s",
        attrs: {
          src: "/img/logo-dyadey-main.svg",
          alt: "Dyadey",
          width: "171",
          height: "33",
        },
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "u-m-b3 u-text-intro@s u-m-b1@s" }, [
        _vm._v("Password Reset"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "u-m-b4 u-text-small@s u-m-b2@s" }, [
        _vm._v("Please enter a new password below."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("input", {
        staticClass: "c-btn u-width-full",
        attrs: { type: "submit", value: "Reset Password" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }